/*------------------------------
Fonts
------------------------------*/
@font-face {
  font-family: Matter;
  font-weight: 500;
  font-style: normal;
  src: url('../assets/fonts/matter-medium.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: Matter;
  font-weight: 300;
  font-style: normal;
  src: url('../assets/fonts/matter-light.woff2') format('woff2');
  font-display: swap;
}

/*------------------------------
Generic
------------------------------*/
*,
*:before,
*:after {
  box-sizing: border-box;
}
html {
  font-size: 10px;
}

body {
  background: #000;
  color: #fff;
  margin: 0;
  padding: 0;
  font-family: Matter, Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  font-weight: 300;
}

.wrapper {
  padding: 4.4rem 5rem 6rem;

  @media (max-width: 440px) {
    padding: 4.4rem 2.5rem 6rem;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

p {
}

h1 {
  font-size: 15em;
  font-weight: 300;
  margin: 0 0 0.5em;

  @media (max-width: 1280px) {
    font-size: 10em;
  }

  @media (max-width: 1024px) {
    font-size: 15vw;
    margin-bottom: 0.8em;
  }
}

h3 {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0;
}

.link {
  display: inline-block;
  
  @media (max-width: 440px) { 
    transform: translateX(4px)!important;
  }
}

.overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  mix-blend-mode: difference;
  transform: scaleY(0);
  pointer-events: none;
}

.logo {
  width: 133px;
  height: 20px;
  fill: #ffffff;
  margin-bottom: 4rem;
}


/*------------------------------
Experiments
------------------------------*/
.experiments {
  display: flex;
  flex-wrap: wrap;
  
  .experiment {
    flex: 0 0 33.333%;
    font-size: 1.8rem;
    font-weight: 500;
    
    @media (max-width: 1200px) {
      flex: 0 0 50%;
    }

    @media (max-width: 780px) {
      flex: 0 0 100%;
    }

    & a {
      background: #000;
      display: block;
      position: relative;
      z-index: 1;
      margin-top: -1px;
      margin-right: 3rem;
      overflow: hidden;

      div {
        padding: 1rem 0;
        border-top: 1px solid rgba(255, 255, 255, .1);
        border-bottom: 1px solid rgba(255, 255, 255, .1);
        opacity: 0;
        display: flex;
        align-items: center;
      }

      @media (max-width: 1200px) {
        margin-right: 2rem;
      }

      @media (max-width: 640px) {
        margin-right: 0;
      }

      & img {
        width: auto;
        height: 60px;
        margin-right: 10px;
      }

      &:visited {
        &:after {
          color: white;
        }
      }

      &:after {
        content: '✓';
        position: absolute;
        right: 10px;
        color: black;
      }
    }

    & em {
      font-weight: 300;
      font-size: 1.2rem;
      font-style: normal;
      display: inline-block;
      width: 17px;
    }

    & span {
      display: inline-block;
      pointer-events: none;
    }
  }
}

ul {
  margin: 0;
  padding: 0;
  li {
    list-style-type: none;
    padding: 1rem 0;
    font-size: 1.6rem;
    border-bottom: 1px solid #000;
    & a {
      background: #fff;
      padding: 1rem 0;
      display: block;
      position: relative;
      z-index: 1;
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;
      margin-top: -1px;
    }
  }
}

.no-border {
  li {
    border-bottom: none;
    padding: 0;
  }
}

/*------------------------------
Title
------------------------------*/
.title {
  position: relative;
  z-index: 1;
}

.title-line-reference {
  letter-spacing: 0.004em;
  color: rgba(0, 0, 0, 0);
}

.title-line {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

/*------------------------------
Canvas
------------------------------*/
.canvas {
  margin-top: 1em;
  margin-bottom: 2em;
  height: 25vw!important;
  opacity: 0;
}

iframe {
  display: none !important;
}
